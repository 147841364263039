import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
} from "@material-tailwind/react";
import { motion } from "framer-motion";

const ScheduledIrrigation = () => {
  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    // Simulating data fetch
    const fetchedSchedules = [
      { id: 1, zone: "Zone 1", date: "2024-07-26", crop: "Banana", status: "In Progress", volume: "20000 L", remarks: "Initial Flow" },
      { id: 2, zone: "Zone 2", date: "2024-07-26", crop: "Tomatoes", status: "Scheduled", volume: "15000 L", remarks: "Regular watering" },
    ];
    setSchedules(fetchedSchedules);
  }, []);

  return (
    <Card className="w-full overflow-hidden ">
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="m-0 p-4 bg-gradient-to-br from-green-400 to-blue-500"
      >
        <Typography variant="h5" color="white" className="mb-2">
          Irrigation Schedule
        </Typography>
      </CardHeader>
      <CardBody className="px-0 pt-0 pb-2">
        <div className="overflow-x-auto">
          <table className="w-full min-w-[640px] table-auto">
            <thead>
              <tr>
                {["Zone", "Date", "Crop", "Status", "Volume", "Remarks", ""].map((el) => (
                  <th
                    key={el}
                    className="border-b border-blue-gray-50 py-3 px-6 text-left"
                  >
                    <Typography
                      variant="small"
                      className="text-[11px] font-medium uppercase text-black"
                    >
                      {el}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {schedules.map(({ id, zone, date, crop, status, volume, remarks }, index) => {
                const isLast = index === schedules.length - 1;
                const classes = isLast ? "p-3" : "p-3 border-b border-blue-gray-50";

                return (
                  <motion.tr
                    key={id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <td className={classes}>
                      <Typography variant="small" color="black" className="font-normal">
                        {zone}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography variant="small" color="black" className="font-normal">
                        {date}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography variant="small" color="black" className="font-normal">
                        {crop}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography variant="small" color="black" className="font-normal">
                        {status}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography variant="small" color="black" className="font-normal">
                        {volume}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography variant="small" color="black" className="font-normal">
                        {remarks}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Button size="sm" color="black" ripple="light">
                        Edit
                      </Button>
                    </td>
                  </motion.tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default ScheduledIrrigation;