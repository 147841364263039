import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Typography } from "@material-tailwind/react";
import { motion } from "framer-motion";
import { GET } from '../../utils/ApiHandler';
import { useSelector } from 'react-redux';

const ZonesValvesMapping = () => {
    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const { token,tenantId } = useSelector((state) => state.login);

    useEffect(() => {
        const fetchProfiles = async () => {
            try {
                const data = await GET(`${process.env.REACT_APP_API_URL}/api/v1/irrigation/tenantIrrigationProf?project_code=${tenantId}`, token);
                console.log(data.data[0])
                if (data.success) {
                    setProfiles(data.data);
                }
            } catch (error) {
                console.error("Error fetching profiles:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchProfiles();
    }, [token]);

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="p-8"
        >
            <Card>
                <CardHeader color="blue" className="p-5">
                    <Typography variant="h5" color="white">
                        Zones-Valves Mapping
                    </Typography>
                </CardHeader>
                <CardBody>
                    {loading ? (
                        <Typography>Loading...</Typography>
                    ) : profiles.length === 0 ? (
                        <Typography className="text-center py-4">No Configuration Initialized</Typography>
                    ) : (
                        <table className="min-w-full bg-white border border-gray-200">
                            <tbody>
                                {profiles.map((profile) => (
                                    <tr key={profile._id} className="border-b hover:bg-gray-100">
                                        <td className="py-2 px-4">
                                            {Object.keys(profile.zonesValvesMapping).length === 0 ? (
                                                <Typography>No zones-valves mapping available</Typography>
                                            ) : (
                                                <table className="min-w-full bg-gray-50 border border-gray-200">
                                                    <thead className="bg-blue-200">
                                                        <tr>
                                                            <th className="py-2 px-4 text-center">Zone</th>
                                                            <th className="py-2 px-4 text-center">Valve</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.entries(profile.zonesValvesMapping).map(([zone, valve]) => (
                                                            <tr key={zone} className="border-b">
                                                                <td className="py-2 px-4 text-center">{zone}</td>
                                                                <td className="py-2 px-4 text-center">{valve}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </CardBody>
            </Card>
        </motion.div>
    );
};

export default ZonesValvesMapping;
