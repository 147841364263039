import React, { useState, useEffect } from 'react';
import { Card, CardBody, Typography } from "@material-tailwind/react";
import { motion } from "framer-motion";
import pdfUrl from '../../Assets/SoilProfilingReport/report.pdf';

const SoilProfilingReport = () => {
  // const [pdfUrl] = useState("./Assets/doc.pdf"); // Replace with actual PDF URL
  const [description, setDescription] = useState("Loading soil profile description...");

  useEffect(() => {
    // Simulate loading description
    setTimeout(() => {
      setDescription("The soil profile report indicates a loamy soil with good water retention capabilities. pH levels are slightly acidic, suggesting the need for lime application in certain zones.");
    }, 2000);
  }, []);

  return (
    <Card className="w-full overflow-hidden bg-gradient-to-br from-green-400 to-blue-500">
      <CardBody>
        <Typography variant="h4" color="white" className="mb-4 font-semibold">
          Soil Profiling Report
        </Typography>
        <div className="flex flex-col space-y-4">
          <div className="w-full">
            <iframe src={pdfUrl} className="w-full h-96 border-2 border-white rounded" title="Soil Profile Report" />
          </div>
          <div className="w-full">
            <Typography variant="h5" color="white" className="mb-2 font-semibold">
              Description
            </Typography>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Typography color="white">
                {description}
              </Typography>
            </motion.div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default SoilProfilingReport;