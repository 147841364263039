import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSpring, animated } from 'react-spring';
import { FaLeaf, FaThermometerHalf, FaSeedling, FaCloudRain } from 'react-icons/fa';
import { useSelector } from "react-redux";
import { GET } from '../utils/ApiHandler';

const Weather = () => {
  const { token } = useSelector((state) => state.login);
  const [isLoading, setIsLoading] = useState(true);
  const [weatherData, setWeatherData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GET(`${process.env.REACT_APP_API_URL}/api/v1/analytics`, token);
        console.log(response.data.weatherData)
        if (response && response.data && response.data.analytics) {
          setWeatherData(response.data.analytics);
        }
        setIsLoading(false);
      } catch (e) {
        console.error("Error fetching data:", e);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [token]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isHovered && !isLoading) {
        setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
      }
    }, 4000);
    return () => clearInterval(timer);
  }, [isHovered, isLoading]);

  const springProps = useSpring({
    scale: isHovered ? 1.05 : 1,
    config: { tension: 300, friction: 10 },
  });

  const getWeatherData = () => {
    if (!weatherData || !weatherData.iotData) return [];
    const { temprature, humidity, rainFall, pressure } = weatherData.iotData;
    return [
      { name: 'Temperature', value: temprature.latestReading, unit: '°C', icon: FaThermometerHalf, color: 'from-blue-400 to-blue-600', analyticsKey: 'temperatureAnalytics' },
      { name: 'Humidity', value: humidity.latestReading, unit: '%', icon: FaSeedling, color: 'from-green-400 to-green-600', analyticsKey: 'humidityAnalytics' },
      { name: 'Rainfall', value: rainFall.lastHour, unit: 'mm', icon: FaCloudRain, color: 'from-red-400 to-red-600', analyticsKey: 'rainfallAnalytics' },
      { name: 'Pressure', value: pressure.latestReading.toFixed(2), unit: 'hPa', icon: FaLeaf, color: 'from-teal-400 to-teal-600', analyticsKey: 'pressureAnalytics' },
    ];
  };

  return (
    <animated.div
      style={springProps}
      className="w-full max-w-md mx-auto bg-gradient-to-br from-green-50 to-blue-50 rounded-xl shadow-2xl overflow-hidden"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="p-6 space-y-4">
        <h2 className="text-2xl font-bold text-green-800">Weather</h2>
        <div className="relative h-48">
          <AnimatePresence>
            {isLoading ? (
              <div className="absolute inset-0 flex items-center justify-center">
                <p className="text-xl font-semibold text-gray-600">Loading...</p>
              </div>
            )
              : getWeatherData().length > 0 ? (
                getWeatherData().map((data, index) => (
                  index === activeIndex && (
                    <motion.div
                      key={data.name}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.5 }}
                      className="absolute inset-0 flex flex-col items-center justify-center"
                    >
                      <div className={`w-24 h-24 rounded-full bg-gradient-to-br ${data.color} flex items-center justify-center mb-4`}>
                        <data.icon className={`text-4xl text-white ${isLoading ? 'animate-spin' : ''}`} />
                      </div>
                      <h3 className="text-xl font-semibold text-gray-800">{data.name}</h3>
                      <p className="text-3xl font-bold text-green-600">
                        {data.value} {data.unit}
                      </p>
                    </motion.div>
                  )
                ))
              ) : (
                <div className="absolute inset-0 flex items-center justify-center">
                  <p className="text-xl font-semibold text-gray-600">No Data Available</p>
                </div>
              )}
          </AnimatePresence>
        </div>
        <div className="flex justify-center space-x-2">
          {getWeatherData().map((_, index) => (
            <button
              key={index}
              className={`w-3 h-3 rounded-full ${index === activeIndex ? 'bg-green-500' : 'bg-gray-300'}`}
              onClick={() => setActiveIndex(index)}
            />
          ))}
        </div>
      </div>
      <div className="bg-gradient-to-r from-green-400 to-blue-500 p-4">
        <p className="text-sm text-white text-center">
          {isLoading ? (
            "Analyzing"
          ) : weatherData && getWeatherData()[activeIndex] && weatherData.analytics[getWeatherData()[activeIndex].analyticsKey] ? (
            `${weatherData.analytics[getWeatherData()[activeIndex].analyticsKey].status}: ${weatherData.analytics[getWeatherData()[activeIndex].analyticsKey].description}`
          ) : (
            "No analytics available for this parameter"
          )}
        </p>
      </div>
    </animated.div>
  );
};

export default Weather;