import React, { useState } from 'react';
import axios from 'axios';
import {
  Input,
  Checkbox,
  Button,
  Typography,
  Textarea,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    agreeToPolicy: false
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'agreeToPolicy' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/contact`, formData);
      setResponseMessage(response.data.message);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error sending message:', error);
      setResponseMessage('Failed to send message. Please try again.');
      setIsSubmitted(true);
    }
  };

  const handleCloseDialog = () => {
    setIsSubmitted(false);
    setFormData({ name: '', email: '', message: '', agreeToPolicy: false });
  };

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-gray-100">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
        <div className="w-full max-w-md mx-auto">
          <Typography variant="h2" color="blue-gray" className="mb-2">
            Request a Demo
          </Typography>
          <Typography color="gray" className="mb-8">
            Whether you have questions or you would just like to say hello, contact us.
          </Typography>
          {!isSubmitted ? (
            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <Input
                type="text"
                name="name"
                label="Name"
                value={formData.name}
                onChange={handleChange}
                required
                className="!border !border-gray-300 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
              />

              <Input
                type="email"
                name="email"
                label="Email"
                value={formData.email}
                onChange={handleChange}
                required
                className="!border !border-gray-300 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
              />
              <Textarea
                name="message"
                label="Message"
                value={formData.message}
                onChange={handleChange}
                required
                className="!border !border-gray-300 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
              />

              <Checkbox
                name="agreeToPolicy"
                checked={formData.agreeToPolicy}
                onChange={handleChange}
                label={
                  <Typography
                    variant="small"
                    color="gray"
                    className="flex items-center font-normal"
                  >
                    You agree to our friendly{' '}
                    <a
                      href="#"
                      className="font-medium transition-colors hover:text-gray-900 ml-1"
                    >
                      Privacy Policy
                    </a>
                    .
                  </Typography>
                }
                containerProps={{ className: "-ml-2.5" }}
              />
              <Button type="submit" className="mt-6" fullWidth color="gray">
                SEND MESSAGE
              </Button>
            </form>
          ) : (
            <Dialog open={isSubmitted} handler={handleCloseDialog}>
              <DialogHeader>Thank You</DialogHeader>
              <DialogBody divider>
                <Typography color="blue-gray" className="font-normal">
                  {responseMessage}
                </Typography>
              </DialogBody>
              <DialogFooter>
                <Button variant="gradient" color="green" onClick={handleCloseDialog}>
                  <span>Close</span>
                </Button>
              </DialogFooter>
            </Dialog>
          )}
        </div>
      </div>
      <div className="lg:w-1/2 bg-black text-white p-12 flex flex-col justify-center">
        <Typography variant="h3" className="mb-4">
          Contact Information
        </Typography>
        <Typography color="white" className="mb-8">
          Fill up the form and our Team will get back to you within 24 hours.
        </Typography>
        <div className="space-y-4">
          <div className="flex items-center">
            <Typography className="mr-2">📞</Typography>
            <Typography>+918618944125</Typography>
          </div>
          <div className="flex items-center">
            <Typography className="mr-2">✉️</Typography>
            <Typography>connect@kiaanagrow.com</Typography>
          </div>
          <div className="flex items-center">
            <Typography className="mr-2">🎫</Typography>
            <Typography>24X7 Support</Typography>
          </div>
        </div>
        {/* <div className="mt-8 flex space-x-4">
          <FaTwitter size={24} />
          <FaLinkedin size={24} />
          <FaDribbble size={24} />
          <FaFacebook size={24} />
        </div> */}
      </div>
    </div>
  );
};

export default Contact;