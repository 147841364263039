import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Typography} from "@material-tailwind/react";
import { motion } from "framer-motion";
import Alerts from './Alerts';

export default function AIInsights() {
  const [insight, setInsight] = useState("");

  useEffect(() => {
    const insights = [
      "Optimal watering time: 6 AM - 8 AM",
      "Soil pH levels indicate need for adjustment",
      "Crop stress detected in Zone 3, increasing irrigation",
    ];
    const interval = setInterval(() => {
      setInsight(insights[Math.floor(Math.random() * insights.length)]);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Card className="w-full overflow-hidden">
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="m-0 p-4 bg-gradient-to-br from-green-400 to-blue-500"
      >
        <Typography variant="h5" color="white" className="mb-2">
          AI Insights
        </Typography>
      </CardHeader>
      <CardBody>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="space-y-2 text-black"
        >
          <p>Analyzing data for optimal irrigation patterns...</p>
          <motion.p
            key={insight}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {insight}
          </motion.p>
          <p className="space-y-2 text-black">Predicted water savings: 15% this week</p>
          <p className="space-y-2 text-black">Suggested action: Reduce watering in Zone 2</p>
        </motion.div>
        <div className='mt-4'>
          <Alerts />
        </div>
      </CardBody>
    </Card>
  );
};