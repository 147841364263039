import React from 'react';
import { Card, CardBody, Typography } from "@material-tailwind/react";
import { CurrencyDollarIcon, UsersIcon, UserPlusIcon, ChartBarIcon} from "@heroicons/react/24/solid";
import { FaUserAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';

const TenantCard = ({ icon, title, value, change, changeColor }) => {
  return (
    <motion.div whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 300 }}>
      <Card className="overflow-hidden">
        <CardBody className="flex items-center p-4">
          <div className="rounded-full bg-blue-500 p-3 mr-4">
            <FaUserAlt className="h-6 w-6 text-white" />
          </div>
          <div>
            <Typography variant="small" color="blue-gray" className="font-normal mb-1">
              {title}
            </Typography>
            <Typography variant="h4" color="blue-gray" className="mb-1">
              {value}
            </Typography>
            <Typography variant="small" className='text-green-500'>
              {change}
            </Typography>
          </div>
        </CardBody>
      </Card>
    </motion.div>
  );
};

export default TenantCard;