import React, { useState, useEffect } from 'react';
import { Card, CardBody, Typography, Progress, Avatar, IconButton } from "@material-tailwind/react";
import { motion } from 'framer-motion';
import { EllipsisVerticalIcon, BellIcon, PlusIcon, ShoppingCartIcon, CreditCardIcon, LockClosedIcon, CameraIcon } from "@heroicons/react/24/solid";

const ProjectCard = ({ project }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="mb-4 last:mb-0"
  >
    <div className="flex items-center justify-between mb-2">
      <div className="flex items-center">
        <div className={`w-10 h-10 rounded-md flex items-center justify-center ${project.iconBg} mr-3`}>
          {project.icon}
        </div>
        <Typography variant="h6" color="blue-gray">
          {project.name}
        </Typography>
      </div>
      <Typography variant="small" color="blue-gray">
        ₹{project.investment}
      </Typography>
    </div>
    <div className="flex items-center justify-between mb-2">
      {/* <Avatar.Group>
        {project.members.map((member, index) => (
          <Avatar key={index} src={member} size="sm" />
        ))}
      </Avatar.Group> */}
      <Typography variant="small" color="blue-gray">
        {project.completion}%
      </Typography>
    </div>
    <Progress value={project.completion} color={project.completion === 100 ? "green" : "blue"} className="h-1" />
  </motion.div>
);

const OrderItem = ({ order }) => (
  <motion.div
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.5 }}
    className="flex items-center mb-4 last:mb-0"
  >
    <div className={`w-10 h-10 rounded-full flex items-center justify-center ${order.iconBg} mr-3`}>
      {order.icon}
    </div>
    <div className="flex-grow">
      <Typography variant="small" color="blue-gray" className="font-medium">
        {order.title}
      </Typography>
      <Typography variant="small" color="gray">
        {order.date}
      </Typography>
    </div>
  </motion.div>
);

const ProjectOrders = () => {
  const projects = [
    { name: "Avaregere", iconBg: "bg-black", icon: "AV", investment: "2.5 Cr", members: ["/img/avatar1.jpg", "/img/avatar2.jpg", "/img/avatar3.jpg", "/img/avatar4.jpg"], completion: 90 },
    { name: "Kottegala", iconBg: "bg-black", icon: "KG", investment: "3.5 Cr", members: ["/img/avatar2.jpg", "/img/avatar5.jpg"], completion: 10 },
    { name: "Chamalapura", iconBg: "bg-black", icon: "CH", investment: "3.5 Cr", members: ["/img/avatar3.jpg", "/img/avatar6.jpg"], completion: 10 },
    { name: "Agara", iconBg: "bg-black", icon: "AG", investment: "1.0 Cr", members: ["/img/avatar1.jpg", "/img/avatar2.jpg", "/img/avatar3.jpg", "/img/avatar4.jpg"], completion: 100 },
  ];

  const initialOrders = [
    { title: "Smart Pole Order", date: "22 DEC 7:20 PM", icon: <BellIcon className="h-5 w-5 text-white" />, iconBg: "bg-blue-gray-500" },
    { title: "New order #1832412", date: "21 DEC 11 PM", icon: <PlusIcon className="h-5 w-5 text-white" />, iconBg: "bg-blue-500" },
    { title: "Server payments for April", date: "21 DEC 9:34 PM", icon: <ShoppingCartIcon className="h-5 w-5 text-white" />, iconBg: "bg-orange-500" },
    { title: "New card added for order #4395133", date: "20 DEC 2:20 AM", icon: <CreditCardIcon className="h-5 w-5 text-white" />, iconBg: "bg-purple-500" },
    { title: "Unlock packages for development", date: "18 DEC 4:54 AM", icon: <LockClosedIcon className="h-5 w-5 text-white" />, iconBg: "bg-red-500" },
    { title: "New order #9583120", date: "17 DEC", icon: <CameraIcon className="h-5 w-5 text-white" />, iconBg: "bg-green-500" },
  ];

  const [orders, setOrders] = useState(initialOrders);

  useEffect(() => {
    const interval = setInterval(() => {
      setOrders(prevOrders => {
        return prevOrders.map(order => {
          // Randomly update some orders
          if (Math.random() > 0.7) {
            return {
              ...order,
              title: updateOrderTitle(order.title),
              date: updateOrderDate()
            };
          }
          return order;
        });
      });
    }, 5000); // Update every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const updateOrderTitle = (title) => {
    if (title.includes('₹')) {
      const amount = parseFloat(title.replace(/[^0-9.-]+/g, ""));
      const newAmount = Math.round(amount * (1 + (Math.random() - 0.5) * 0.1)); // ±5% change
      return `₹${newAmount.toLocaleString('en-IN')}, ${title.split(',')[1]}`;
    } else if (title.includes('#')) {
      const orderNumber = parseInt(title.split('#')[1]);
      const newOrderNumber = orderNumber + Math.floor(Math.random() * 100);
      return `${title.split('#')[0]}#${newOrderNumber}`;
    }
    return title;
  };

  const updateOrderDate = () => {
    const date = new Date();
    return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${date.getHours()}:${date.getMinutes()} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
  };

  return (
    <div className="flex flex-col lg:flex-row gap-6">
      <Card className="w-full lg:w-2/3">
        <CardBody>
          <div className="flex justify-between items-center mb-6">
            <div>
              <Typography variant="h5" color="blue-gray">
                Projects
              </Typography>
              <Typography variant="small" color="gray">
                <span className="font-medium text-green-500">30 done</span> this month
              </Typography>
            </div>
            <IconButton variant="text" color="blue-gray">
              <EllipsisVerticalIcon className="h-6 w-6" />
            </IconButton>
          </div>
          <div className="hidden lg:grid grid-cols-4 gap-4 mb-6 text-sm font-medium text-gray-600">
            <div>COMPANIES</div>
            <div>MEMBERS</div>
            <div>INVESTMENT</div>
            <div>COMPLETION</div>
          </div>
          {projects.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </CardBody>
      </Card>
      <Card className="w-full lg:w-1/3">
        <CardBody>
          <div className="flex justify-between items-center mb-6">
            <Typography variant="h5" color="blue-gray">
              Kiaan Smart Pole Orders Overview
            </Typography>
          </div>
          <Typography variant="small" color="gray" className="mb-6">
            <span className="font-medium text-green-500">24% this month</span>
          </Typography>
          {orders.map((order, index) => (
            <OrderItem key={index} order={order} />
          ))}
        </CardBody>
      </Card>
    </div>
  );
};

export default ProjectOrders;