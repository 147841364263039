import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaThermometerHalf, FaTint, FaWind, FaLeaf, FaSeedling, FaSun, FaCloudSun, FaCompass, FaCloudRain, FaTachometerAlt, FaInfoCircle, FaDownload } from 'react-icons/fa';
import { WiHumidity } from 'react-icons/wi';
import { useSelector } from "react-redux";
import { GET } from '../../utils/ApiHandler';
import analytics from '../../Assets/Reports/analytics.gif'

const Reports = () => {
  const { token } = useSelector((state) => state.login);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [selectedParameter, setSelectedParameter] = useState(null);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GET(`${process.env.REACT_APP_API_URL}/api/v1/analytics`, token);
        if (response && response.data) {
          setData(response.data.analytics);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [token]);

  const parameters = [
    { name: 'Temperature', icon: FaThermometerHalf, color: 'bg-red-500', unit: '°C', key: 'temprature', valueAccessor: (data) => data.iotData.temprature?.latestReading, analyticsAccessor: 'temperatureAnalytics' },
    { name: 'Humidity', icon: WiHumidity, color: 'bg-blue-500', unit: '%', key: 'humidity', valueAccessor: (data) => data.iotData.humidity?.latestReading, analyticsAccessor: 'humidityAnalytics' },
    { name: 'Rainfall', icon: FaCloudRain, color: 'bg-indigo-500', unit: 'mm', key: 'rainFall', valueAccessor: (data) => data.iotData.rainFall?.last24Hour, analyticsAccessor: 'rainfallAnalytics' },
    { name: 'Pressure', icon: FaTachometerAlt, color: 'bg-purple-500', unit: 'hPa', key: 'pressure', valueAccessor: (data) => data.iotData.pressure?.latestReading, analyticsAccessor: 'pressureAnalytics' },
    { name: 'Soil Moisture L1', icon: FaTint, color: 'bg-blue-300', unit: 'cbar', key: 'soilMoistureL1', valueAccessor: (data) => data.iotData.soilMoistureL1, analyticsAccessor: 'soilMoistureL1Analytics' },
    { name: 'Soil Moisture L2', icon: FaSeedling, color: 'bg-green-300', unit: 'cbar', key: 'soilMoistureL2', valueAccessor: (data) => data.iotData.soilMoistureL2, analyticsAccessor: 'soilMoistureL2Analytics' },
    { name: 'Soil Temperature', icon: FaThermometerHalf, color: 'bg-brown-500', unit: '°C', key: 'soilTemprature', valueAccessor: (data) => data.iotData.soilTemprature, analyticsAccessor: 'soilTemperatureAnalytics' },
    { name: 'Leaf Wetness', icon: FaLeaf, color: 'bg-green-500', unit: '%', key: 'leafWetness', valueAccessor: (data) => data.iotData.leafWetness, analyticsAccessor: 'leafWetnessAnalytics' },
    { name: 'Lux', icon: FaCloudSun, color: 'bg-brown-300', unit: 'lx', key: 'lux', valueAccessor: (data) => data.iotData.lux?.latestReading, analyticsAccessor: 'luxAnalytics' },
    { name: 'Solar Intensity', icon: FaSun, color: 'bg-orange-500', unit: 'W/m²', key: 'solarIntensity', valueAccessor: (data) => data.iotData.solarIntensity?.latestReading, analyticsAccessor: 'solarIntensityAnalytics' },
    { name: 'Wind Speed', icon: FaWind, color: 'bg-teal-500', unit: 'km/h', key: 'windSpeed', valueAccessor: (data) => data.iotData.windSpeed, analyticsAccessor: 'windSpeedAnalytics' },
    { name: 'Wind Direction', icon: FaCompass, color: 'bg-gray-500', unit: '', key: 'windDirection', valueAccessor: (data) => data.iotData.windDirection, analyticsAccessor: 'windDirectionAnalytics' },
  ];

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
  };

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-white">
        <div className="w-32 h-32 mb-4">
          <img 
            src={analytics} 
            alt="Analyzing Farm Metrics"
            className="w-full h-full object-contain"
          />
        </div>
        <h2 className="text-xl font-semibold text-gray-700">Loading Farm Analytics...</h2>
      </div>
    );
  }

  const getValue = (param) => {
    if (!data || !data.iotData) return 'N/A';
    const value = param.valueAccessor(data);
    return value !== undefined ? value : 'N/A';
  };

  const getAnalytics = (param) => {
    if (!data || !data.analytics || !data.analytics[param.analyticsAccessor]) return null;
    return data.analytics[param.analyticsAccessor];
  };

  const renderValue = (param) => {
    const value = getValue(param);
    if (value === 'N/A') return value;
    
    if (param.key === 'windDirection') {
      return value;
    }
    
    const numValue = parseFloat(value);
    if (isNaN(numValue)) return value;
    
    return `${numValue.toFixed(2)} ${param.unit}`;
  };

  const renderAnalyticsSummary = (param) => {
    const analytics = getAnalytics(param);
    if (!analytics) return 'No analytics available';
    
    return `${analytics.status}: ${analytics.description}`;
  };

  const getCardStyle = (param) => {
    const value = getValue(param);
    if (value === 'N/A') return 'bg-gray-300';
    
    const numValue = parseFloat(value);
    if (isNaN(numValue)) return param.color;
    
    return param.color;
  };

  const handleDownloadReport = async () => {
    setIsGeneratingPDF(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/analytics/generate-pdf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ data: data.analytics }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate PDF');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'farm_analytics_report.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsGeneratingPDF(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 bg-gray-100 min-h-screen">
      <div className="flex justify-between items-center mb-8">
        <h1 className="md:text-4xl text-xl font-bold text-green-800">Farm Analytics</h1>
        <button
          onClick={handleDownloadReport}
          disabled={isGeneratingPDF}
          className={`bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded flex items-center ${isGeneratingPDF ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {isGeneratingPDF ? (
            <span className="mr-2 hidden md:inline">Generating...</span>
          ) : (
            <>
              <FaDownload className="mr-1" />
              <span className="hidden md:inline">Download Report</span>
            </>
          )}
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {parameters.map((param, index) => (
          <motion.div
            key={param.key}
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl relative"
          >
            <div className={`p-4 ${getCardStyle(param)} text-white`}>
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold">{param.name}</h2>
                <param.icon className="text-2xl" />
              </div>
            </div>
            <div className="p-4">
              <p className="text-3xl font-bold mb-2 text-gray-800">
                {renderValue(param)}
              </p>
              <p className="text-sm text-black font-semibold">
                {renderAnalyticsSummary(param)}
              </p>
              <button
                onClick={() => setSelectedParameter(param)}
                className="absolute bottom-2 right-2 text-blue-500 hover:text-blue-700"
              >
                <FaInfoCircle size={24} />
              </button>
            </div>
          </motion.div>
        ))}
      </div>

      <AnimatePresence>
        {selectedParameter && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setSelectedParameter(null)}
          >
            <motion.div
              className="bg-white rounded-lg p-6 max-w-lg w-full max-h-[90vh] overflow-y-auto"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">{selectedParameter.name}</h2>
                <button
                  onClick={() => setSelectedParameter(null)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              {renderAnalyticsDetails(selectedParameter)}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );

  function renderAnalyticsDetails(param) {
    const analytics = getAnalytics(param);
    if (!analytics) return <p>No analytics available for this parameter.</p>;

    return (
      <div>
        <p className="mb-2"><strong>Current Value:</strong> {renderValue(param)}</p>
        <p className="mb-2"><strong>Status:</strong> <span className={`px-2 py-1 rounded ${getStatusColor(analytics.status)}`}>{analytics.status}</span></p>
        <p className="mb-2"><strong>Optimal Range:</strong> {analytics.optimalRange || analytics.optimalDirections}</p>
        <h3 className="text-lg font-semibold mt-4 mb-2">Description:</h3>
        <p className="mb-4">{analytics.description}</p>
        <h3 className="text-lg font-semibold mb-2">Recommended Actions:</h3>
        <p>{analytics.actions}</p>
      </div>
    );
  }

  function getStatusColor(status) {
    switch (status.toLowerCase()) {
      case 'optimal':
        return 'bg-green-200 text-green-800';
      case 'low':
        return 'bg-yellow-200 text-yellow-800';
      case 'high':
        return 'bg-red-200 text-red-800';
      default:
        return 'bg-gray-200 text-gray-800';
    }
  }
};

export default Reports;