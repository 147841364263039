import React from 'react';
import { Typography } from "@material-tailwind/react";
import DataTable from '../DataTable/DataTable';
import { GET, DELETE } from '../../utils/ApiHandler';
import { useSelector } from 'react-redux';

const TenantIrrigationProfileList = () => {
  const TABLE_HEAD = ["Tenant Project Code", "Username", "Actions"];
  const { token } = useSelector((state) => state.login);

  const fetchProfiles = async () => {
    const data = await GET(`${process.env.REACT_APP_API_URL}/api/v1/irrigation/tenantIrrigationProfiles`, token);
    return data?.data || [];
  };

  const deleteProfile = async (profileId) => {
    return await DELETE(`${process.env.REACT_APP_API_URL}/api/v1/irrigation/deleteTenantIrrigationProfile`,{profileId:profileId}, token);
  };

  const renderProfileRow = (profile) => (
    <>
      <td className="p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          {profile.tenantProjectCode}
        </Typography>
      </td>
      <td className="p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          {profile.username}
        </Typography>
      </td>
    </>
  );

  return (
    <DataTable
      title="Tenant Irrigation Profiles"
      subtitle="Manage your organization's Tenant Irrigation Profiles"
      addButtonText="Initialize Tenant Irrigation Profile"
      addButtonLink="/admin/tenant-irrigation-profiles/add"
      tableHead={TABLE_HEAD}
      fetchData={fetchProfiles}
      deleteItem={deleteProfile}
      renderTableRow={renderProfileRow}
      searchFields={['tenantProjectCode', 'username']}
    />
  );
};

export default TenantIrrigationProfileList;
