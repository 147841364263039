import { useState, useEffect } from "react";
import {
    HomeIcon,
    CogIcon,
    Square3Stack3DIcon,
} from "@heroicons/react/24/solid";
import {
    Badge,
    Card,
    SpeedDial,
    SpeedDialHandler,
    SpeedDialContent,
    SpeedDialAction,
    IconButton,
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Typography,
} from "@material-tailwind/react";
import { BellAlertIcon } from "@heroicons/react/24/solid";
import { FaBell } from 'react-icons/fa';
import axios from 'axios';
import { GET } from "../../utils/ApiHandler";

const speak = (message) => {
    const utterance = new SpeechSynthesisUtterance(message);
    speechSynthesis.speak(utterance);
}

const Alerts = () => {
    const [openAlert, setOpenAlert] = useState(false);
    const [analytics, setAnalytics] = useState([]);
    const [alertCount, setAlertCount] = useState(0);

    useEffect(() => {
        fetchAnalytics();
    }, []);

    const fetchAnalytics = async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_API_URL}/api/v1/analytics`);
            const data = response.data.analytics.analytics;
            const filteredAnalytics = Object.entries(data)
                .filter(([key, value]) => typeof value === 'object' && value.status.startsWith('Low'))
                .map(([key, value]) => ({
                    title: key,
                    description: value.description
                }));
            setAnalytics(filteredAnalytics);
            setAlertCount(filteredAnalytics.length);
        } catch (error) {
            console.error('Error fetching analytics:', error);
        }
    };

    const handleAlertOpen = () => {
        setOpenAlert(!openAlert);
        speak(`You have ${alertCount} important alerts`);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 pointer-events-none">
            <Menu open={openAlert} handler={setOpenAlert} className='relative top-4'>
                <MenuHandler>
                    <div className="fixed bottom-16 right-4 pointer-events-auto">
                        <SpeedDial>
                            <SpeedDialHandler onClick={handleAlertOpen}>
                                <IconButton size="lg" className="rounded-full">
                                    <Badge content={alertCount} placement='top-end' color="green">
                                        <FaBell className="h-5 w-5 transition-transform group-hover:rotate-12" />
                                    </Badge>
                                </IconButton>
                            </SpeedDialHandler>
                            <SpeedDialContent>
                                <SpeedDialAction className="h-16 w-16 bg-gradient-to-br from-green-400 to-blue-500">
                                    <HomeIcon className="h-5 w-5 text-black" />
                                    <Typography color="black" className="text-xs font-bold">
                                        Home
                                    </Typography>
                                </SpeedDialAction>
                                <SpeedDialAction className="h-16 w-16 bg-gradient-to-br from-green-400 to-blue-500">
                                    <CogIcon className="h-5 w-5 text-black" />
                                    <Typography color="black" className="text-xs font-bold">
                                        Settings
                                    </Typography>
                                </SpeedDialAction>
                                <SpeedDialAction className="h-16 w-16 bg-gradient-to-br from-green-400 to-blue-500">
                                    <Square3Stack3DIcon className="h-5 w-5 text-black" />
                                    <Typography color="black" className="text-xs font-bold">
                                        Pages
                                    </Typography>
                                </SpeedDialAction>
                            </SpeedDialContent>
                        </SpeedDial>
                    </div>
                </MenuHandler>
                <MenuList className="w-[20rem] grid-cols-3 gap-3 overflow-visible lg:grid pointer-events-auto">
                    <Card
                        color="gray"
                        shadow={false}
                        className="col-span-3 flex h-full w-full items-center justify-center rounded-2xl p-4"
                    >
                        <BellAlertIcon strokeWidth={1} className="h-10 w-10" />
                        <Typography className="mt-5 text-center" variant="h5">
                            Real Time Alerts
                        </Typography>
                    </Card>
                    <ul className="col-span-4 flex w-full flex-col gap-1">
                        {analytics.map(({ title, description }) => (
                            <a href="#" key={title}>
                                <MenuItem>
                                    <Typography variant="h6" color="blue-gray" className="mb-1">
                                        {title}
                                    </Typography>
                                    <Typography
                                        variant="small"
                                        color="gray"
                                        className="font-xs"
                                    >
                                        {description.split(".")[0]}
                                    </Typography>
                                </MenuItem>
                            </a>
                        ))}
                    </ul>
                </MenuList>
            </Menu>
        </div>
    )
}

export default Alerts;
