import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    Button,
    Typography,
    Alert,
    IconButton,
} from "@material-tailwind/react";
import {
    UserCircleIcon,
    PlusIcon,
    TrashIcon,
} from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import { GET, PUT, POST } from '../../utils/ApiHandler';

const TenantIrrigationProfileForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { token, tenantId } = useSelector((state) => state.login);

    const [formData, setFormData] = useState({
        tenantProjectCode: '',
        username: '',
        password: '',
        zonesValvesMapping: [{ zone: '', valve: '' }]
    });
    const [alert, setAlert] = useState({ show: false, message: '', color: 'green' });

    useEffect(() => {
        if (id) {
            const getProfile = async () => {
                let data = await GET(`${process.env.REACT_APP_API_URL}/api/v1/irrigation/tenantIrrigationProfile?project_code=${id}`, token);
                if (data.data) {
                    const { tenantProjectCode, username, password, zonesValvesMapping } = data.data[0];
                    setFormData({
                        tenantProjectCode,
                        username,
                        password,
                        zonesValvesMapping: Object.entries(zonesValvesMapping).map(([zone, valve]) => ({ zone, valve }))
                    });
                }
            };
            getProfile();
        } else {
            setFormData(prevState => ({
                ...prevState,
                tenantProjectCode: tenantId
            }));
        }
    }, [token, id, tenantId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const zonesValvesMappingObj = Object.fromEntries(
            formData.zonesValvesMapping.map(({ zone, valve }) => [zone, valve])
        );
        const dataToSubmit = {
            ...formData,
            zonesValvesMapping: zonesValvesMappingObj
        };
        let data;
        if (id) {
            data = await PUT(`${process.env.REACT_APP_API_URL}/api/v1/irrigation/tenantIrrigationProfile?project_code=${id}`, dataToSubmit, token);
        } else {
            data = await POST(`${process.env.REACT_APP_API_URL}/api/v1/irrigation/createTenantIrrigationProfile`, dataToSubmit, token);
        }
        if (data) {
            setAlert({
                show: true,
                message: `Successfully ${id ? 'updated' : 'added'} Tenant Irrigation Profile!`,
                color: 'green'
            });
            setTimeout(() => navigate('/admin/tenant-irrigation-profiles'), 2000);
        } else {
            setAlert({
                show: true,
                message: `Error ${id ? 'updating' : 'adding'} Tenant Irrigation Profile`,
                color: 'red'
            });
        }
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        if (name === 'zone' || name === 'valve') {
            const newZonesValvesMapping = [...formData.zonesValvesMapping];
            newZonesValvesMapping[index][name] = value;
            setFormData({ ...formData, zonesValvesMapping: newZonesValvesMapping });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleAddZoneValve = () => {
        setFormData({
            ...formData,
            zonesValvesMapping: [...formData.zonesValvesMapping, { zone: '', valve: '' }]
        });
    };

    const handleRemoveZoneValve = (index) => {
        const newZonesValvesMapping = formData.zonesValvesMapping.filter((_, i) => i !== index);
        setFormData({ ...formData, zonesValvesMapping: newZonesValvesMapping });
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="min-h-screen p-8 bg-gradient-to-br from-green-400 to-blue-500 flex items-center justify-center rounded-xl"
        >
            <Card className="w-full max-w-[40rem] bg-white bg-opacity-90 backdrop-blur-md">
                <CardHeader
                    color="blue"
                    floated={false}
                    shadow={false}
                    className="m-0 grid place-items-center rounded-b-none py-8 px-4 text-center"
                >
                    <div className="mb-4 rounded-full border border-white/10 bg-white/10 p-6 text-white">
                        <UserCircleIcon className="h-10 w-10" />
                    </div>
                    <Typography variant="h4" color="white">
                        {id ? 'Edit' : 'Initialize'} Tenant Irrigation Profile
                    </Typography>
                </CardHeader>
                <CardBody className="flex flex-col gap-4">
                    <Input
                        label="Tenant Project Code"
                        name="tenantProjectCode"
                        value={formData.tenantProjectCode}
                        onChange={handleInputChange}
                        disabled={!!id}
                    />
                    <Input
                        label="Username"
                        name="username"
                        value={formData.username}
                        onChange={handleInputChange}
                    />
                    <Input
                        type="password"
                        label="Password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                    />
                    {formData.zonesValvesMapping.map((zv, index) => (
                        <div key={index} className="flex flex-col sm:flex-row gap-2 items-center">
                            <Input
                                label="Zone"
                                name="zone"
                                value={zv.zone}
                                onChange={(e) => handleInputChange(e, index)}
                                className="flex-1"
                            />
                            <Input
                                label="Valve"
                                name="valve"
                                value={zv.valve}
                                onChange={(e) => handleInputChange(e, index)}
                                className="flex-1"
                            />
                            <IconButton
                                color="red"
                                onClick={() => handleRemoveZoneValve(index)}
                            >
                                <TrashIcon className="h-5 w-5" />
                            </IconButton>
                        </div>
                    ))}
                    <Button
                        color="blue"
                        onClick={handleAddZoneValve}
                        className="flex items-center gap-2"
                    >
                        <PlusIcon className="h-5 w-5" /> Add Zone-Valve
                    </Button>
                </CardBody>
                <CardFooter className="pt-0">
                    <Button
                        variant="gradient"
                        color="green"
                        fullWidth
                        onClick={handleSubmit}
                    >
                        {id ? 'Update' : 'Save'}
                    </Button>
                </CardFooter>
            </Card>
            {alert.show && (
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    className="fixed top-4 right-4 z-50"
                >
                    <Alert
                        color={alert.color}
                        dismissible={{
                            onClose: () => setAlert({ ...alert, show: false }),
                        }}
                    >
                        {alert.message}
                    </Alert>
                </motion.div>
            )}
        </motion.div>
    );
};

export default TenantIrrigationProfileForm;
