import React, { useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, Typography } from "@material-tailwind/react";
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import layout from '../../Assets/Irrigation/plotlayout.png';

const ThreeDPlotMap = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    let scene, camera, renderer, controls;

    const init = () => {
      scene = new THREE.Scene();
      camera = new THREE.PerspectiveCamera(75, mountRef.current.clientWidth / mountRef.current.clientHeight, 0.1, 1000);
      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
      mountRef.current.appendChild(renderer.domElement);

      // Load texture
      const textureLoader = new THREE.TextureLoader();
      textureLoader.load(layout, (texture) => {
        const planeGeometry = new THREE.PlaneGeometry(10, 10);
        const planeMaterial = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
        const plane = new THREE.Mesh(planeGeometry, planeMaterial);
        plane.rotation.x = -Math.PI / 2;
        scene.add(plane);
      });

      // Add lighting
      const ambientLight = new THREE.AmbientLight(0x404040);
      scene.add(ambientLight);
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
      scene.add(directionalLight);

      // Orbit controls
      controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.25;
      controls.enableZoom = true;

      camera.position.set(5, 5, 5);
      camera.lookAt(scene.position);

      // Pipe Paths
      const pipePaths = [
        { start: [-0.1, 0.01, -0.19], end: [1.3, 0.01, -0.2] },
        { start: [1.3, 0.01, -0.2], end: [1.2, 0.01, 3.4] },
        { start: [1.2, 0.01, 3.4], end: [4.7, 0.01, 3.4] },
        { start: [1.3, 0.01, 0.8], end: [4.22, 0.01, 0.8] },
        { start: [-0.1, 0.01, -0.5], end: [-1.8, 0.01, -0.5] },
        { start: [-1, 0.01, -0.5], end: [-1.3, 0.01, 3.3] },
        { start: [-1, 0.01, -0.4], end: [-0.6, 0.01, -4.8] },
        { start: [-1.8, 0.01, -0.5], end: [-2.1, 0.01, 3.3] },
        { start: [-1.8, 0.01, -0.5], end: [-1.6, 0.01, -3.2] },
        { start: [-0.8, 0.01, -3.1], end: [-3.8, 0.01, -3.2] },
        { start: [-2.3, 0.01, -3.2], end: [-2.2, 0.01, -4.7] },
        { start: [-3.8, 0.01, -3.2], end: [-3.5, 0.01, -4.9] },
      ];

      pipePaths.forEach(path => {
        const material = new THREE.LineBasicMaterial({ color: 0x0000ff });
        const points = [
          new THREE.Vector3(...path.start),
          new THREE.Vector3(...path.end)
        ];
        const geometry = new THREE.BufferGeometry().setFromPoints(points);
        const line = new THREE.Line(geometry, material);
        scene.add(line);
      });

      // Animate water flow along pipes
      const flowSpeed = 0.008;
      const flowMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff });
      const flowGeometry = new THREE.SphereGeometry(0.05, 16, 16);
      const flowParticles = [];

      pipePaths.forEach(path => {
        const particle = new THREE.Mesh(flowGeometry, flowMaterial);
        particle.position.set(...path.start);
        particle.userData = {
          start: new THREE.Vector3(...path.start),
          end: new THREE.Vector3(...path.end),
          progress: 0
        };
        flowParticles.push(particle);
        scene.add(particle);
      });

      const animateParticles = () => {
        flowParticles.forEach(particle => {
          particle.userData.progress += flowSpeed;
          if (particle.userData.progress >= 1) {
            particle.userData.progress = 0;
            particle.position.set(...particle.userData.start.toArray());
          } else {
            particle.position.lerpVectors(particle.userData.start, particle.userData.end, particle.userData.progress);
          }
        });
      };

      // Animation loop
      const animate = () => {
        requestAnimationFrame(animate);
        animateParticles();
        controls.update();
        renderer.render(scene, camera);
      };
      animate();
    };

    init();

    // Clean up
    return () => {
      if (mountRef.current && renderer.domElement) {
        mountRef.current.removeChild(renderer.domElement);
      }
      controls.dispose();
      renderer.dispose();
      scene.traverse((object) => {
        if (!object.isMesh) return;
        object.geometry.dispose();
        if (object.material.isMaterial) {
          object.material.dispose();
        }
      });
    };
  }, []);

  return (
    <Card className="w-full overflow-hidden bg-gradient-to-br from-green-400 to-blue-500">
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="m-0 p-4"
      >
        <Typography variant="h5" color="white" className="mb-2">
          3D Plot Map
        </Typography>
      </CardHeader>
      <CardBody className="p-0">
        <div
          ref={mountRef}
          style={{ width: '100%', height: '420px' }}
          className="w-full h-96"
        />
      </CardBody>
    </Card>
  );
};

export default ThreeDPlotMap;