import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Typography, Button, Select, Option, Input } from "@material-tailwind/react";
import { motion } from "framer-motion";
import { GET, POST } from '../../utils/ApiHandler';
import {
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";

import valveon from '../../../src/Assets/Irrigation/valve-on.gif'
import valveoff from '../../Assets/Irrigation/valve-off.gif'


const ControlPanel = () => {
    const [valves, setValves] = useState([]);
    const [selectedValve, setSelectedValve] = useState(null);
    const [method, setMethod] = useState("Time-based");
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [volume, setVolume] = useState(0);
    const [alertopen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [valveStatus, setValveStatus] = useState("No Valve Selected");

    const handleAlertOpen = () => setAlertOpen(!alertopen);

    const getValves = async () => {
        const resp = await GET(`${process.env.REACT_APP_API_URL}/api/v1/irrigation/profiles/valves`);
        console.log(resp.data.valves)
        if (resp.success) {
            setValves(resp.data.valves);
        }
    }

    useEffect(() => {
        getValves();
        // Set up polling to update valve status
        // const intervalId = setInterval(getValves, 5000); // Poll every 5 seconds
        // return () => clearInterval(intervalId);
    }, []);

    const handleValveSelect = (valve) => {
        setSelectedValve(valve);
        setMethod(valve.config.method || "Time-based");
        setHours(valve.config.hours || "0");
        setMinutes(valve.config.minutes || "0");
        setVolume(valve.config.volume || "0");
        updateValveStatus(valve);
    }

    const updateValveStatus = (valve) => {
        if (valve.config.method === "Time-based") {
            if (valve.config.hours > 0 || valve.config.minutes > 0) {
                setValveStatus("Running");
            } else {
                setValveStatus("Stopped");
            }
        } else if (valve.config.method === "Volume-based") {
            if (valve.config.volume > 0) {
                setValveStatus("Running");
            } else {
                setValveStatus("Stopped");
            }
        }
    }

    const handleStartIrrigation = async () => {
        if (!selectedValve) {
            setAlertMessage("Please select a valve");
            setAlertOpen(true);
            return;
        }
        const payload = {
            valveName: selectedValve.name,
            method,
            hours: method === "Time-based" ? hours : 0,
            minutes: method === "Time-based" ? minutes : 0,
            volume: method === "Volume-based" ? volume : 0,
        };
        const resp = await POST(`${process.env.REACT_APP_API_URL}/api/v1/irrigation/modify-valve`, payload);
        if (resp) {
            setAlertMessage("Valve Config Modified successfully");
            await getValves(); // Refresh valve list
            updateValveStatus(selectedValve);
        } else {
            setAlertMessage("Failed to Modify Config");
        }
        setAlertOpen(true);
    }

    const handleStopIrrigation = async () => {
        if (!selectedValve) {
            setAlertMessage("Please select a valve");
            setAlertOpen(true);
            return;
        }
        const payload = { valveName: selectedValve.name };
        const resp = await POST(`${process.env.REACT_APP_API_URL}/api/v1/irrigation/stop-valve`, payload);
        if (resp.success) {
            setAlertMessage("Valve stopped successfully");
            await getValves(); // Refresh valve list
            updateValveStatus(selectedValve);
        } else {
            setAlertMessage("Failed to stop irrigation");
        }
        setAlertOpen(true);
    }

    const isValveRunning = valveStatus === "Running";

    return (
        <Card className="w-full max-w-[26rem] overflow-hidden relative">
            
            <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="m-0 p-4 bg-gradient-to-br from-green-400 to-blue-500"
            >
                <Typography variant="h5" color="white" className="mb-2">
                    Control Panel
                </Typography>
                <img className={`absolute top-2 right-2 w-12 h-12 rounded-full`} src={`${isValveRunning ? valveon : valveoff}`} />
            </CardHeader>
            
            <CardBody className="text-center">
                <div className="flex flex-col space-y-4">
                    <Select
                        label="Select Valve"
                        value={selectedValve?.name || ""}
                        color="blue"


                    >
                        {valves.map((valve) => (
                            <Option key={valve.name} value={valve.name} onClick={() => handleValveSelect(valve)}>
                                {valve.name} - {valve.config.method}
                                {valve.config.method === "Time-based"
                                    ? ` (${valve.config.hours}h ${valve.config.minutes}m)`
                                    : ` (${valve.config.volume}L)`}
                            </Option>
                        ))}
                    </Select>

                    <Typography variant="h6" color={isValveRunning ? "green" : "red"}>
                        Valve Status: {valveStatus}
                    </Typography>

                    <Select
                        label="Method"
                        value={method}
                        onChange={(value) => setMethod(value)}
                        color="blue"
                    >
                        <Option value="Time-based">Time-based</Option>
                        <Option value="Volume-based">Volume-based</Option>
                    </Select>

                    {method === "Time-based" && (
                        <>
                            <Input
                                type="number"
                                label="Hours"
                                value={hours}
                                onChange={(e) => setHours(parseInt(e.target.value))}
                            />
                            <Input
                                type="number"
                                label="Minutes"
                                value={minutes}
                                onChange={(e) => setMinutes(parseInt(e.target.value))}
                            />
                        </>
                    )}

                    {method === "Volume-based" && (
                        <Input
                            type="number"
                            label="Volume (liters)"
                            value={volume}
                            onChange={(e) => setVolume(parseInt(e.target.value))}
                        />
                    )}

                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                        <Button
                            size="lg"
                            color={isValveRunning ? "red" : "green"}
                            ripple={true}
                            fullWidth={true}
                            onClick={isValveRunning ? handleStartIrrigation : handleStartIrrigation}
                        >
                            {isValveRunning ? "Modify Valve Config" : "Modify Valve Config"}
                        </Button>
                    </motion.div>
                </div>
            </CardBody>
            <Dialog open={alertopen} handler={handleAlertOpen}>
                <DialogHeader>Irrigation Alert</DialogHeader>
                <DialogBody>
                    {alertMessage}
                </DialogBody>
                <DialogFooter>
                    <Button variant="gradient" color="green" onClick={handleAlertOpen}>
                        <span>Okay</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </Card>
    );
};

export default ControlPanel;