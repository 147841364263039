import React from 'react';
import {  Typography } from "@material-tailwind/react";
import DownloadReports from './DownloadReports';
import IrrigationHistory from './IrrigationHistory';
import EnergyConsumption from './EnergyConsumption';
import FertigationHistory from './FertigationHistory';
import SoilProfilingReport from './SoilProfiling';
import ScheduledIrrigation from './ScheduledIrrigation';
import ScheduledFertigation from './ScheduledFertigation';
const IrrigationHistoricalData = () => {
  return (
    <div className="container mx-auto p-4">
      <Typography variant="h2" color="blue-gray" className="mb-4">
        Irrigation and Fertigation Module
      </Typography>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <div className="lg:col-span-3">
          <IrrigationHistory />
        </div>
        <div className="lg:col-span-3">
          <FertigationHistory />
        </div>
        <div className="lg:col-span-2" >
          <EnergyConsumption />
          <DownloadReports />
        </div>
        
        
        
        <div className="lg:col-span-1">
          <SoilProfilingReport />
        </div>
        <div className="lg:col-span-3">
          <ScheduledIrrigation />
        </div>
        <div className="lg:col-span-3">
          <ScheduledFertigation />
        </div>
      </div>
    </div>
  );
};

export default IrrigationHistoricalData;