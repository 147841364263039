import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Alert,
  Typography,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Textarea,
  IconButton,
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { GET, DELETE, POST } from "../../utils/ApiHandler";
import dayjs from "dayjs";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { motion, AnimatePresence } from "framer-motion";

const FileUpload = ({ path, onChange, id }) => {
  const [preview, setPreview] = useState([]);
  const { token, tenantId } = useSelector((state) => state.login);
  const filePickerRef = useRef();
  const [isUploading, setIsUploading] = useState(false);

  const pickFileHandler = () => {
    filePickerRef.current.click();
  };

  const pickHandler = async (event) => {
    if (event.target.files && event.target.files.length !== 0) {
      setIsUploading(true);
      const files = Array.from(event.target.files);
      const uploadedFiles = [];
      for (const file of files) {
        const formData = new FormData();
        formData.append("file", file);
        try {
          const data = await POST(
            `${process.env.REACT_APP_API_URL}/api/v1/common/file/upload?tenantId=${tenantId}&path=process`,
            formData,
            token
          );
          if (data.success) {
            const { common: sasUrl, fileName, blobName } = data.data;
            uploadedFiles.push({ sasUrl, fileName, blobName });
          } else {
            console.error("Upload failed:", data.message);
          }
        } catch (error) {
          console.error("Upload error:", error);
        }
      }
      setPreview((prev) => [...prev, ...uploadedFiles.map((file) => file.sasUrl)]);
      onChange(uploadedFiles);
      setIsUploading(false);
    }
  };

  return (
    <div className="relative">
      <input
        id={id}
        type="file"
        className="hidden"
        ref={filePickerRef}
        accept=".jpg,.jpeg,.png,.mp4,.mpeg,.pdf"
        multiple
        onChange={pickHandler}
      />
      <Button
        type="button"
        onClick={pickFileHandler}
        className="relative"
        disabled={isUploading}
      >
        {isUploading ? <Spinner className="h-4 w-4" /> : "UPLOAD MEDIA"}
      </Button>
    </div>
  );
};

const LogProcess = () => {
  const { token, tenantId } = useSelector((state) => state.login);

  const [currentWeek, setCurrentWeek] = useState(null);
  const [processData, setProcessData] = useState([]);
  const [processlogData, setProcesslogData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [projectStartDate, setProjectStartDate] = useState("");
  const [weekRange, setWeekRange] = useState("");
  const [newLog, setNewLog] = useState("");
  const [newMedia, setNewMedia] = useState([]);
  const [weekOffset, setWeekOffset] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("green");
  const [isEditing, setIsEditing] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const weeksPerPage = isMobile ? 5 : 8;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const totalWeeks = 55;

  const calculateCurrentWeek = useCallback((startDate) => {
    const start = dayjs(startDate);
    const now = dayjs();
    return Math.floor(now.diff(start, "day") / 7) + 1;
  }, []);

  const fetchProcessData = useCallback(
    async (week) => {
      try {
        setLoading(true);
        const response = await GET(
          `${process.env.REACT_APP_API_URL}/api/v1/process/history?week=${week}`,
          token
        );
        setProcessData(response.data.process || []);
      } catch (error) {
        console.error("Error fetching process data:", error);
        setAlertMessage("Failed to fetch process data");
        setAlertColor("red");
        setAlertOpen(true);
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  useEffect(() => {
    const getProjectStartDate = async () => {
      try {
        let data = await GET(
          `${process.env.REACT_APP_API_URL}/api/v1/tenants/list?tenantId=${tenantId}`,
          token
        );
        if (data?.data?.tenants.length > 0) {
          const startDate = data?.data?.tenants[0].project_start_date;
          setProjectStartDate(startDate);
          const calculatedCurrentWeek = calculateCurrentWeek(startDate);
          setCurrentWeek(calculatedCurrentWeek);
          setWeekOffset(Math.floor((calculatedCurrentWeek - 1) / weeksPerPage) * weeksPerPage);
        }
      } catch (error) {
        console.error("Error fetching project start date:", error);
        setAlertMessage("Failed to fetch project start date");
        setAlertColor("red");
        setAlertOpen(true);
      }
    };
    getProjectStartDate();
  }, [token, tenantId, calculateCurrentWeek]);

  const fetchProcesslogData = useCallback(
    async (processId, tenantId, week) => {
      try {
        const response = await GET(
          `${process.env.REACT_APP_API_URL}/api/v1/processlogs/history?processId=${processId}&tenantId=${tenantId}&week=${week}`,
          token
        );
        if (response.success) {
          setProcesslogData(prevState => ({
            ...prevState,
            [processId]: response.data.processlog[0] || null
          }));
        }
      } catch (error) {
        console.error("Error fetching process log data:", error);
        setAlertMessage("Failed to fetch process log data");
        setAlertColor("red");
        setAlertOpen(true);
      }
    },
    [token]
  );

  useEffect(() => {
    if (currentWeek !== null && processData.length > 0) {
      processData.forEach(process => {
        fetchProcesslogData(process._id, tenantId, currentWeek);
      });
    }
  }, [currentWeek, processData, fetchProcesslogData, tenantId]);

  useEffect(() => {
    if (currentWeek !== null) {
      fetchProcessData(currentWeek);
    }
  }, [currentWeek, fetchProcessData]);

  useEffect(() => {
    if (projectStartDate && currentWeek !== null) {
      const weekStart = dayjs(projectStartDate).add((currentWeek - 1) * 7, "day");
      const weekEnd = weekStart.add(6, "day");
      setWeekRange(`${weekStart.format("MMM DD, YYYY")} - ${weekEnd.format("MMM DD, YYYY")}`);
    }
  }, [currentWeek, projectStartDate]);

  const handleWeekChange = (week) => {
    setCurrentWeek(week);
    setSelectedProcess(null);
    setIsEditing(false);
  };

  const handleLogClick = (process) => {
    if (selectedProcess && selectedProcess._id === process._id) {
      setSelectedProcess(null);
      setNewLog("");
      setNewMedia([]);
      setIsEditing(false);
    } else {
      setSelectedProcess(process);
      setNewLog(processlogData[process._id]?.processLog || "");
      setNewMedia([]);
      setIsEditing(false);
    }
  };

  const handleLogChange = (event) => {
    setNewLog(event.target.value);
  };

  const handleMediaDelete = async (index, isNewMedia = false) => {
    try {
      let blobName;
      if (isNewMedia) {
        const fileToDelete = newMedia[index];
        blobName = fileToDelete.blobName;
        await DELETE(
          `${process.env.REACT_APP_API_URL}/api/v1/common/file/remove?tenantId=${tenantId}&blobName=${blobName}`,
          {},
          token
        );
        setNewMedia((prev) => prev.filter((_, i) => i !== index));
      } else {
        const fileToDelete = processlogData[selectedProcess._id].media[index];
        blobName = fileToDelete.blobName || fileToDelete.name;
        const response = await DELETE(
          `${process.env.REACT_APP_API_URL}/api/v1/processlogs/delete-media`,
          { processId: selectedProcess._id, blobName, tenantId, week: currentWeek },
          token
        );
        if (response.success) {
          setProcesslogData((prev) => ({
            ...prev,
            [selectedProcess._id]: {
              ...prev[selectedProcess._id],
              media: prev[selectedProcess._id].media.filter((_, i) => i !== index)
            }
          }));
        }
      }
      setAlertMessage("File deleted successfully");
      setAlertColor("green");
      setAlertOpen(true);
    } catch (error) {
      console.error("Error deleting file:", error);
      setAlertMessage("Failed to delete file");
      setAlertColor("red");
      setAlertOpen(true);
    }
  };

  const handleSubmitLog = async () => {
    try {
      setLoading(true);

      // Get the existing media from the current process log
      const existingMedia = processlogData[selectedProcess._id]?.media || [];

      // If new media is uploaded, combine existing media with new media
      let updatedMedia = existingMedia;
      if (newMedia.length > 0) {
        // Only add new media files to the media list
        updatedMedia = [
          ...existingMedia,
          ...newMedia.filter(
            newFile =>
              !existingMedia.some(existingFile => existingFile.blobName === newFile.blobName)
          )
        ];
      }

      const updatedProcessLog = {
        processId: selectedProcess._id,
        tenantId: tenantId,
        week_number: currentWeek,
        process_week_range: weekRange,
        processLog: newLog,
        media: updatedMedia, // Send only updated media (new or existing)
      };

      const response = await POST(
        `${process.env.REACT_APP_API_URL}/api/v1/processlogs/create`,
        updatedProcessLog,
        token
      );
      if (response.success) {
        setAlertMessage("Process log updated successfully");
        setAlertColor("green");
        setAlertOpen(true);

        // Refetch the process log data for the updated process
        fetchProcesslogData(selectedProcess._id, tenantId, currentWeek);

        // Clear the state
        setSelectedProcess(null);
        setNewLog("");
        setNewMedia([]);
        setIsEditing(false);
      } else {
        setAlertMessage("Failed to update process log");
        setAlertColor("red");
        setAlertOpen(true);
      }
    } catch (error) {
      console.error("Error updating process log:", error);
      setAlertMessage("An error occurred while updating the process log");
      setAlertColor("red");
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };


  const handleMediaUpload = (uploadedFiles) => {
    setNewMedia((prev) => [...prev, ...uploadedFiles]);
  };

  const handleNextWeeks = () => {
    const newOffset = Math.min(weekOffset + weeksPerPage, totalWeeks - weeksPerPage);
    setWeekOffset(newOffset);
    setCurrentWeek(newOffset + 1);
  };

  const handlePreviousWeeks = () => {
    const newOffset = Math.max(weekOffset - weeksPerPage, 0);
    setWeekOffset(newOffset);
    setCurrentWeek(newOffset + 1);
  };

  const handleReturnToCurrentWeek = useCallback(() => {
    if (projectStartDate) {
      const currentWeekNumber = calculateCurrentWeek(projectStartDate);
      setCurrentWeek(currentWeekNumber);
      setWeekOffset(Math.floor((currentWeekNumber - 1) / weeksPerPage) * weeksPerPage);
      fetchProcessData(currentWeekNumber);
    }
  }, [projectStartDate, calculateCurrentWeek, weeksPerPage, fetchProcessData]);

  const renderMediaPreview = (files, isNewMedia = false) => {
    return files.map((file, index) => {
      const src = file.sasUrl;
      return (
        <div key={index}>
          {isEditing && (
            <IconButton
              className="absolute top-1 right-1 z-10 bg-white bg-opacity-70 hover:bg-opacity-90"
              onClick={() => handleMediaDelete(index, isNewMedia)}
            >
              <XCircleIcon className="h-5 w-5 text-red-500" />
            </IconButton>
          )}
          {src.includes(".mp4") || src.includes(".mpeg") || src.includes(".mov") ? (
            <video src={src} controls className="w-full h-full object-cover rounded-lg" />
          ) : src.includes(".pdf") ? (
            <iframe src={src} className="w-full h-full" title={`pdf-preview-${index}`} />
          ) : src.includes(".jpg") || src.includes(".jpeg") || src.includes(".png") || src.includes(".webp") ? (
            <img src={src} alt={`preview-${index}`} className="w-full h-full object-cover rounded-lg" />
          ) : null}
        </div>
      );
    });
  };

  const weekTabs = [];
  for (let i = 0; i < weeksPerPage; i++) {
    const weekNumber = weekOffset + i + 1;
    if (weekNumber > totalWeeks) break;

    const weekStart = dayjs(projectStartDate).add((weekNumber - 1) * 7, "day");
    const weekEnd = weekStart.add(6, "day");
    const weekRange = `${weekStart.format("MMM DD")} - ${weekEnd.format("MMM DD")}`;

    weekTabs.push({
      label: `Week ${weekNumber}`,
      value: weekNumber.toString(),
      desc: weekRange,
    });
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-400 to-blue-500 p-8 rounded-xl">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h2" color="white" className="mb-4 text-center">
          {projectStartDate ? "Plantation Process Logs" : "Please select a Tenant"}
        </Typography>
        {projectStartDate && (
          <Card className="w-full shadow-xl">
            <CardHeader floated={false} className="h-24">
              <div className="flex justify-between items-center h-full px-4">
                <Button
                  size="sm"
                  variant="text"
                  className="flex items-center gap-2"
                  onClick={handlePreviousWeeks}
                  disabled={weekOffset === 0}
                >
                  Previous
                </Button>
                <Button
                  size="sm"
                  variant="text"
                  className="flex items-center gap-2"
                  onClick={handleNextWeeks}
                  disabled={weekOffset + weeksPerPage >= totalWeeks}
                >
                  Next
                </Button>
              </div>
            </CardHeader>
            <CardBody className="px-0 pt-0">
              <Tabs value={currentWeek.toString()} className="overflow-visible">
                <TabsHeader className="relative z-0">
                  {weekTabs.map(({ label, value, desc }) => (
                    <Tab
                      key={value}
                      value={value}
                      onClick={() => handleWeekChange(parseInt(value))}
                      className="w-48"
                    >
                      <div className="flex flex-col items-center gap-1">
                        {label}
                        <span className="text-xs font-normal">{desc}</span>
                      </div>
                    </Tab>
                  ))}
                </TabsHeader>
                <TabsBody
                  className="!overflow-x-hidden !overflow-y-visible"
                  animate={{
                    initial: { x: 400 },
                    mount: { x: 0 },
                    unmount: { x: -400 },
                  }}
                >
                  <TabPanel value={currentWeek.toString()} className="py-0">
                    <AnimatePresence mode="wait">
                      {loading ? (
                        <motion.div
                          key="loading"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="flex justify-center items-center h-64"
                        >
                          <Spinner className="h-12 w-12" />
                        </motion.div>
                      ) : processData.length === 0 ? (
                        <motion.div
                          key="no-data"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="text-center py-8"
                        >
                          <Typography variant="h5">
                            No Plantation Process data available for this week
                          </Typography>
                        </motion.div>
                      ) : (
                        <motion.div
                          key="process-data"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          <Typography variant="h4" color="blue-gray" className="pt-2 mb-4 text-center">
                            {processData[0]?.title || "Plantation Process"}
                          </Typography>
                          {processData.map((process, index) => (
                            <motion.div
                              key={process._id}
                              initial={{ opacity: 0, y: 20 }}
                              animate={{ opacity: 1, y: 0 }}
                              transition={{ delay: index * 0.1 }}
                            >
                              <Card className="mb-4 overflow-hidden">
                                <CardBody>
                                  <Typography variant="h5" color="blue-gray" className="mb-2">
                                    {process.subtitle}
                                  </Typography>
                                  <Typography>{process.description}</Typography>
                                  {processlogData[process._id]?.processLog && (
                                    <div className="mt-4">
                                      <Typography variant="h6" color="blue-gray" className="mb-2">
                                        Process Log
                                      </Typography>
                                      <Typography>{processlogData[process._id].processLog}</Typography>
                                    </div>
                                  )}
                                  <Button
                                    onClick={() => {
                                      handleLogClick(process);
                                      setIsEditing(!isEditing);
                                    }}
                                    className="mt-4"
                                  >
                                    {selectedProcess && selectedProcess._id === process._id && isEditing
                                      ? "Close Log"
                                      : isEditing ? "Cancel Edit" : "Log"}
                                  </Button>
                                </CardBody>
                                {processlogData[process._id]?.media && processlogData[process._id].media.length > 0 && (
                                  <CardFooter className="pt-0">
                                    <Typography variant="h6" color="blue-gray" className="mb-2">
                                      Media
                                    </Typography>
                                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                                      {renderMediaPreview(processlogData[process._id].media)}
                                    </div>
                                  </CardFooter>
                                )}
                              </Card>
                              {selectedProcess && selectedProcess._id === process._id && isEditing && (
                                <Card className="mb-4">
                                  <CardHeader color="blue" className="relative h-16 text-center">
                                    <Typography variant="h5" color="white" className="mt-2">
                                      Edit Log for: {selectedProcess.subtitle}
                                    </Typography>
                                  </CardHeader>
                                  <CardBody>
                                    <Textarea
                                      label="Process Log"
                                      value={newLog}
                                      onChange={handleLogChange}
                                      rows={4}
                                      className="mb-4"
                                    />
                                    <FileUpload
                                      path="process"
                                      onChange={handleMediaUpload}
                                      id={`file-upload-${selectedProcess._id}`}
                                    />
                                    {processlogData[selectedProcess._id]?.media && processlogData[selectedProcess._id].media.length > 0 && (
                                      <div className="mt-4">
                                        <Typography variant="h6" color="blue-gray" className="mb-2">
                                          Existing Media
                                        </Typography>
                                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                                          {renderMediaPreview(processlogData[selectedProcess._id].media)}
                                        </div>
                                      </div>
                                    )}
                                    {newMedia.length > 0 && (
                                      <div className="mt-4">
                                        <Typography variant="h6" color="blue-gray" className="mb-2">
                                          New Media
                                        </Typography>
                                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                                          {renderMediaPreview(newMedia, true)}
                                        </div>
                                      </div>
                                    )}
                                  </CardBody>
                                  <CardFooter className="pt-0">
                                    <Button onClick={handleSubmitLog} fullWidth>
                                      Update Log
                                    </Button>
                                  </CardFooter>
                                </Card>
                              )}
                            </motion.div>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </TabPanel>
                </TabsBody>
              </Tabs>
            </CardBody>
            {/* <CardFooter className="pt-0">
              <Button
                size="lg"
                variant="gradient"
                color="green"
                className="mx-auto"
                onClick={handleReturnToCurrentWeek}
              >
                Return to Current Week
              </Button>
            </CardFooter> */}
          </Card>
        )}
      </motion.div>
      <AnimatePresence>
        {alertOpen && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="fixed top-4 right-4 z-50"
          >
            <Alert
              open={alertOpen}
              onClose={() => setAlertOpen(false)}
              animate={{
                mount: { y: 0 },
                unmount: { y: 100 },
              }}
              color={alertColor}
            >
              {alertMessage}
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LogProcess;