import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { EnhancedNavbar } from '../Components/Navbar/Navbar';
import Sidebar from '../Components/Sidebar/Sidebar';
import { Breadcrumbs } from '@material-tailwind/react';

const Layout = ({ theme }) => {
  const { tenantId } = useSelector((state) => state.login);
  return (
    <div className={`flex flex-col h-screen ${theme === 'dark' ? 'bg-blue-900' : 'bg-blue-100'}`}>
      <EnhancedNavbar theme={theme} />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar theme={theme} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto">
          <div className="container mx-auto px-6 py-8">
            <Breadcrumbs>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
              <p className='font-bold font-sans'>{tenantId}</p>
            </Breadcrumbs>
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;