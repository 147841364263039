import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Typography,
  Alert,
  Spinner,
} from "@material-tailwind/react";
import { FaLeaf, FaShoppingCart, FaUserMd } from 'react-icons/fa';
import { CloudArrowUpIcon } from "@heroicons/react/24/solid";
import { POST } from '../../utils/ApiHandler';
import fertilizer from '../../Assets/DiseaseManagement/fertilizer.jpeg';

const DiseaseManagement = () => {
  const { token } = useSelector((state) => state.login);
  const [isDragging, setIsDragging] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [alert, setAlert] = useState({ show: false, message: '', color: 'green' });
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [isPredicting, setIsPredicting] = useState(false);
  const [prediction, setPrediction] = useState('');
  const [diseaseDescription, setDiseaseDescription] = useState('');
  const [pesticideRecommendation, setPesticideRecommendation] = useState(null);
  const [showSolutions, setShowSolutions] = useState(false);

  // ... (keep all the existing handler functions)
  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      handleFileInput(droppedFile);
    }
  }, []);

  const handleFileInput = (selectedFile) => {
    if (selectedFile) {
      setFile(selectedFile);
      setImagePreview(URL.createObjectURL(selectedFile));
      setIsPredicting(true);
      setAlert({ show: false, message: '', color: 'green' });
      setShowSolutions(false);
    }
  };

  const handleFileInputChange = useCallback((e) => {
    const selectedFile = e.target.files[0];
    handleFileInput(selectedFile);
  }, []);

  const handleUploadClick = () => {
    document.getElementById('file-upload').click();
  };

  const handlePredictClick = () => {
    if (file) {
      handleFile(file);
    } else {
      setAlert({
        show: true,
        message: 'Please select a file to upload.',
        color: 'red'
      });
    }
  };

  const handleFile = async (file) => {
    setLoading(true);
    setAlert({
      show: true,
      message: 'Predicting...',
      color: 'blue'
    });

    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/dm/v1/disease-management`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          if (total > 0) {
            setUploadProgress(Math.round((loaded * 100) / total));
          }
        },
      });

      setPrediction(response.data[0]);
      setAlert({
        show: true,
        message: `Prediction: ${response.data[0]}`,
        color: 'green'
      });

      const body={prompt:`What is ${response.data[0]}`}
      // Fetch disease description from LLM endpoint
      const descriptionResponse = await POST(`${process.env.REACT_APP_API_URL}/api/v1/kiaanagrowgpt`,body,token);
      console.log(descriptionResponse)

      setDiseaseDescription(descriptionResponse.data.gptResponse);

      // Mock pesticide recommendation (replace with actual API call if available)
      setPesticideRecommendation({
        name: "EcoGuard Plus",
        description: "An eco-friendly pesticide effective against a wide range of plant diseases.",
        formula: "N-P-K 5-5-5",
        image: fertilizer
      });

      setShowSolutions(true);
    } catch (error) {
      setAlert({
        show: true,
        message: 'Failed to process the image. Please try again.',
        color: 'red'
      });
    } finally {
      setLoading(false);
      setUploadProgress(0);
    }
  };

  const handleCleanup = () => {
    setFile(null);
    setImagePreview(null);
    setIsPredicting(false);
    setPrediction(null);
    setDiseaseDescription('');
    setPesticideRecommendation(null);
    setAlert({ show: false, message: '', color: 'green' });
    setUploadProgress(0);
    setLoading(false);
    setShowSolutions(false);
  };

  return (
    <div className="min-h-screen p-4 md:p-8">
      <Card className="w-full bg-white shadow-xl overflow-hidden">
        <CardBody className="p-6">
          <div className="mb-8">
            <motion.div
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              className={`border-2 border-dashed rounded-lg p-8 text-center transition-all duration-200 ease-in-out cursor-pointer ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}`}
            >
              <CloudArrowUpIcon className="h-12 w-12 mx-auto text-gray-400" />
              <Typography variant="h6" color="blue-gray" className="mt-4">
                Drop file to upload
              </Typography>
              <Typography variant="small" color="gray" className="mt-2">
                or
              </Typography>
              <div className="mt-4">
                <label htmlFor="file-upload">
                  <Button
                    variant="gradient"
                    color="blue"
                    className="flex items-center gap-2 mx-auto"
                    onClick={isPredicting ? handlePredictClick : handleUploadClick}
                  >
                    <CloudArrowUpIcon className="h-5 w-5" />
                    {isPredicting ? 'Predict' : 'Upload File'}
                  </Button>
                  <input
                    id="file-upload"
                    type="file"
                    className="hidden"
                    onChange={handleFileInputChange}
                  />
                </label>
              </div>
              {file && (
                <Typography variant="small" color="gray" className="mt-4">
                  Selected: {file.name}
                </Typography>
              )}
            </motion.div>
  
            {uploadProgress > 0 && uploadProgress < 100 && (
              <div className="w-full bg-gray-200 rounded-full h-2.5 mt-4">
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: `${uploadProgress}%` }}
                  className="bg-blue-600 h-2.5 rounded-full"
                ></motion.div>
              </div>
            )}
  
            {loading && (
              <div className="flex justify-center items-center mt-4">
                <Spinner color="blue" />
              </div>
            )}
          </div>
  
          <div className="flex flex-col lg:flex-row gap-8">
            <div className="w-full lg:w-1/2">
              {imagePreview && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="mb-4"
                >
                  <Typography variant="h6" color="blue-gray" className="mb-2">
                    Uploaded Image
                  </Typography>
                  <img
                    src={imagePreview}
                    alt="Selected"
                    className="w-full h-auto rounded-lg shadow-md"
                  />
                </motion.div>
              )}
              
            </div>
  
            <AnimatePresence>
              
              {showSolutions && (
                <motion.div
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -50 }}
                  transition={{ duration: 0.5 }}
                  className="w-full lg:w-1/2"
                ><Typography variant="h5" color="blue-gray" className="mb-4">
                Disease Analysis
              </Typography>
              <Typography variant="h6" color="blue-gray" className="mb-2">
                Detected Disease:
              </Typography>
              <Typography color="gray" className="mb-4">
                {prediction}
              </Typography>
              {diseaseDescription && (
                <>
                  <Typography variant="h6" color="blue-gray" className="mb-2">
                    Description:
                  </Typography>
                  <Typography color="gray" className="mb-4">
                    {diseaseDescription}
                  </Typography>
                </>
              )}
                  <Typography variant="h5" color="blue-gray" className="mb-4">
                    Recommended Solutions
                  </Typography>
  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {pesticideRecommendation && (
                      <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="bg-white rounded-lg shadow-md p-4"
                      >
                        <img
                          src={pesticideRecommendation.image}
                          alt={pesticideRecommendation.name}
                          className="w-full h-40 object-cover rounded-lg mb-4"
                        />
                        <Typography variant="h6" color="blue-gray" className="mb-2">
                          {pesticideRecommendation.name}
                        </Typography>
                        <Typography color="gray" className="mb-2">
                          {pesticideRecommendation.description}
                        </Typography>
                        <Typography color="blue-gray" className="font-semibold">
                          Formula: {pesticideRecommendation.formula}
                        </Typography>
                        <Button
                          color="green"
                          ripple="light"
                          className="mt-4 w-full flex items-center justify-center"
                          onClick={() => window.open('https://kiaankart.com', '_blank')}
                        >
                          <FaShoppingCart className="mr-2" /> Buy Now
                        </Button>
                      </motion.div>
                    )}
  
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="bg-white rounded-lg shadow-md p-4 flex flex-col justify-center items-center h-full"
                    >
                      <Typography variant="h6" color="blue-gray" className="mb-2">
                        Expert Consultation
                      </Typography>
                      <Typography color="gray" className="mb-4 text-center">
                        Get personalized advice from our plant health experts.
                      </Typography>
                      <Button
                        color="blue"
                        ripple="light"
                        className="mt-4 w-full flex items-center justify-center"
                        onClick={() => window.open('https://kiaankart.com', '_blank')}
                      >
                        <FaUserMd className="mr-2" /> Book Consultation
                      </Button>
                    </motion.div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
  
          {/* {alert.show && (
            <Alert
              color={alert.color}
              dismissible={{
                onClose: () => setAlert({ ...alert, show: false }),
              }}
              className="mt-4"
            >
              {alert.message}
            </Alert>
          )} */}
  
          {(isPredicting || showSolutions) && (
            <Button
              variant="gradient"
              color="red"
              className="mt-8 w-full"
              onClick={handleCleanup}
            >
              Start Over
            </Button>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default DiseaseManagement;