import React, { useState } from 'react';
import { Card, CardBody, Typography } from "@material-tailwind/react";
import { motion } from "framer-motion";

const Alerts = () => {
  const [alerts, setAlerts] = useState([
    "Irrigation System operating normally",
  ]);

  return (
    <Card className="w-full overflow-hidden bg-gradient-to-br from-green-400 to-blue-500">
      <CardBody>
        <Typography variant="h4" color="black" className="mb-4 font-semibold">
          Alerts
        </Typography>
        <ul className="space-y-2">
          {alerts.map((alert, index) => (
            <motion.li
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-white bg-opacity-20 p-2 rounded-md text-black font-bold"
            >
              {alert}
            </motion.li>
          ))}
        </ul>
      </CardBody>
    </Card>
  );
};

export default Alerts;
