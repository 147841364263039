import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Alert,
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  IconButton,
  Input,
  Spinner,
  Tooltip,
} from "@material-tailwind/react";
import {
  MagnifyingGlassIcon,
  UserPlusIcon,
  PencilIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { motion, AnimatePresence } from 'framer-motion';

const DataTable = ({
  title,
  subtitle,
  addButtonText,
  addButtonLink,
  tableHead,
  fetchData,
  deleteItem,
  renderTableRow,
  searchFields,
}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [alert, setAlert] = useState({ show: false, message: '', color: 'green' });

  useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {
        const fetchedData = await fetchData();
        setData(fetchedData);
      } catch (error) {
        console.error(`Failed to fetch ${title.toLowerCase()}:`, error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDataFromAPI();
  }, [fetchData, title]);

  const showAlert = (message, color) => {
    setAlert({ show: true, message, color });
    setTimeout(() => {
      setAlert({ show: false, message: '', color: 'green' });
    }, 3000); // Auto-dismiss after 3 seconds
  };

  const handleDelete = async (id) => {
    try {
      console.log(id)
      const response = await deleteItem(id);
      if (response.success) {
        setData(prevData => prevData.filter(item => item._id !== id));
        showAlert(`Successfully Deleted ${title}!`, 'green');
      } else {
        showAlert(`Error Deleting ${title}!`, 'red');
      }
    } catch (error) {
      console.error(`Error deleting ${title.toLowerCase()}:`, error);
      showAlert(`Error Deleting ${title}!`, 'red');
    }
  };

  const filteredData = data.filter((item) =>
    searchFields.some(field =>
      item[field].toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <Card className="w-full shadow-xl overflow-hidden bg-white bg-opacity-90 backdrop-blur-md">
      <CardHeader floated={false} shadow={false} className="rounded-none bg-transparent">
        <div className="flex flex-col md:flex-row items-center justify-between gap-4 md:gap-8">
          <motion.div
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <Typography variant="h3" color="blue-gray" className="mb-2">
              {title}
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              {subtitle}
            </Typography>
          </motion.div>
          <div className="flex flex-col sm:flex-row gap-4 w-full md:w-auto">
            <div className="w-full sm:w-72">
              <Input
                label="Search"
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="bg-white"
              />
            </div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link to={addButtonLink}>
                <Button className="flex items-center gap-3 bg-green-500 text-white shadow-lg" size="sm">
                  <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> {addButtonText}
                </Button>
              </Link>
            </motion.div>
          </div>
        </div>
      </CardHeader>
      <CardBody className="p-0 mx-4 overflow-x-auto">
        <AnimatePresence>
          {isLoading ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex justify-center items-center h-64"
            >
              <Spinner className="h-12 w-12" />
            </motion.div>
          ) : (
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {tableHead.map((head) => (
                    <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <motion.tr
                    key={item._id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="even:bg-blue-gray-50/50"
                  >
                    {renderTableRow(item)}
                    <td className="p-4">
                      <Tooltip content={`Edit ${title}`}>
                        <Link to={`edit/${item._id}`}>
                          <IconButton variant="text" color="blue-gray">
                            <PencilIcon className="h-4 w-4" />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      <Tooltip content={`Delete ${title}`}>
                        <IconButton variant="text" color="blue-gray" onClick={() => handleDelete(item._id)}>
                          <TrashIcon className="h-4 w-4" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          )}
        </AnimatePresence>
      </CardBody>
      <AnimatePresence>
        {alert.show && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="fixed top-4 right-4 z-50"
          >
            <Alert
              color={alert.color}
              dismissible={{
                onClose: () => setAlert({ ...alert, show: false }),
              }}
            >
              {alert.message}
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
    </Card>
  );
};

export default DataTable;